/* Footer General Styles */
.footer {
  background-color: #041A42;
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

/* Add a light grey or white space before the footer */
.footer-space {
  background-color: #f0f0f0; /* Light grey */
  height: 2px;
}

/* Footer Container */
.footer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 3 columns for larger screens */
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

footer button {
  background: none;
  border: none;
  color: white;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
  text-decoration: underline;
}

footer button:hover {
  color: #007bff;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-column h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
}

.social-icons a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 20px; /* Increased margin to create more space between icons */
  border-radius: 50%;
  background-color: #1c4a8a; /* Blue background circle */
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
  transition: background-color 0.3s;
}

.social-icons a:hover {
  background-color: #041A42; /* Darker blue on hover */
}

/* Footer Bottom */
.footer-bottom {
  border-top: 1px solid white; /* White line before the copyright */
  padding-top: 20px;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr; /* Stack columns vertically */
  }

  .footer-column {
    margin-bottom: 30px;
  }

  .social-icons {
    margin-bottom: 20px;
  }
}