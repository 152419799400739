/* Privacy Policy Page Styles */
.privacy-container {
  font-family: 'Arial', sans-serif;
  background-color: white; /* White background */
  color: black; /* Default text color */
  padding: 20px;
  line-height: 1.6;
  max-width: 900px;
  margin: 0 auto; /* Centers the container */
  border-radius: 10px;
  
}

/* Title Styling */
.privacy-title {
  color: #003366;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center; /* Center align title */
  letter-spacing: 1.5px;
}

/* Section Headings */
.section-heading {
  color: #1D3557;
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left;/* Center align section headings */
}

/* Paragraph Styling */
.privacy-paragraph {
  color: black;
  margin-bottom: 15px;
  line-height: 1.6;
   /* Neat alignment for long paragraphs */
}

/* List Styling */
.privacy-list {
  list-style-type: disc;
  margin: 0 auto; /* Centers the list */
  padding: 0;
  
  
}

.privacy-list li {
  color: black;
  margin-bottom: 10px;
  font-weight: normal;
}

/* Highlighted Paragraph */
.highlight {
  background-color: #eaf4fc;
  color: #003366;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

/* Responsive Design */
@media (max-width: 768px) {
  .privacy-title {
      font-size: 2rem;
  }

  .section-heading {
      font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .privacy-title {
      font-size: 1.5rem;
  }

  .section-heading {
      font-size: 1.3rem;
  }

  .privacy-container {
      padding: 10px;
  }
}
