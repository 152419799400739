/* General container and text styles */
.service-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  p {
    font-size: 1.1rem;
    color: white;
  }
  
  .text-center {
    text-align: center;
  }
  
  /* Services Section */
  .services-section {
    padding: 50px;
    background-color: white;
  }
  
  .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  @media (min-width: 768px) {
    .grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .image-wrapper img {
    width: 100%;
    height: auto;
  }
  
  .text-wrapper {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .text-wrapper h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #222;
  }
  
  .text-wrapper p {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  /* Key Visa Services Section */
  .key-services-section {
    background-color: #f9f9f9;
    padding: 50px 0;
  }
  
  .key-services-section h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .key-services-section p {
    color: #666;
    margin-bottom: 30px;
  }
  
  .buttons-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  @media (min-width: 768px) {
    .buttons-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .key-visa-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    color: #000;
    border: 1px solid #003366;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    width: fit-content;
  }
  
  /* Icon Style for Visa Services Button */
  .key-visa-button::before {
    content: '✔';
    color: #003366;
    font-size: 1.5rem;
    margin-right: 15px;
    background-color: #ffffff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .key-visa-button:hover {
    background-color: #003366;
    color: #fff;
  }
  
  .key-visa-button:hover::before {
    background-color: #003366;
    color: #fff;
  }
  
  /* Get Started Button */
  .services-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #003366;
    color: #fff;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    align-self: flex-start;
    margin-top: 20px;
  }
  
  .services-button:hover {
    background-color: #002244;
  }
  
  /* Add arrow icon to "Get Started" button */
  .services-button::after {
    content: '→';
    margin-left: 10px;
    font-size: 1.2rem;
  }
  
  /* Visa Sections */
  .visa-section .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  @media (min-width: 768px) {
    .visa-section .grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  /* Reversing the grid for alternating sections */
  .visa-section.reverse-grid .grid {
    direction: rtl; /* Reverse the direction of the grid */
  }
  
  .visa-section.reverse-grid .text-wrapper {
    direction: ltr; /* Ensure the text is still left-aligned */
  }
  
  /* Image styling */
  .visa-section .image-wrapper img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .visa-section .text-wrapper {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .visa-section .text-wrapper h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #222;
  }
  
  .visa-section .text-wrapper p {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  /* Partner Section */
  .partner-section {
    background-color: #ffffff;
    padding: 50px 0;
  }
  
  .partner-section .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .partner-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .partner-section p {
    font-size: 1.1rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
  }
  