/* Updated CSS without the image */
.hero-section {
  text-align: center;
  margin-bottom: 40px;
}
 
.hero-title {
  color: #003366;
  margin-top: 10px;
  font-size: 2rem;
}
 
.hero-subtitle {
  color: #555;
  margin-top: 5px;
  font-size: 1.2rem;
}
 
.pathways-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
 
.visa-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
 
.visa-title {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1D3557;
}
 
.visa-title:hover {
  color: #457b9d;
}
 
@media (max-width: 768px) {
  .hero-title {
    font-size: 1.8rem;
  }
}
 
@media (max-width: 480px) {
  .hero-title {
    font-size: 1.5rem;
  }
 
  .visa-container {
    padding: 10px;
  }
}
 