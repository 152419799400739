/* Contact Page Styles */
.contact-container {
    font-family: 'Arial', sans-serif;
    background-color: white;
    color: black;
    padding: 30px;
    max-width: 600px;
    margin: 50px auto;
    border-radius: 10px;
    
    text-align: center; /* Center the content */
  }
  
  .contact-title {
    color: #003366; /* Same as About page title */
    font-size: 2.5rem;
    margin-bottom: 20px;
    letter-spacing: 1.5px;
  }
  
  .contact-info {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  .contact-text {
    font-size: 1.4rem;
    margin-bottom: 15px;
    line-height: 1.8;
    color: black;
  }
  
  .contact-text a {
    color: #1D3557; /* Matching with section headings */
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact-text a:hover {
    text-decoration: underline;
  }
  
  .contact-highlight {
    background-color: #eaf4fc;
    color: #003366;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    margin-top: 20px;
    font-size: 1.2rem;
  }
  