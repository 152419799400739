/* Modal Styles - Responsive */
@media (max-width: 768px) {
  .ReactModal__Content {
    width: 90%; /* Adjust width for smaller screens */
    padding: 20px;
  }
}

@media (min-width: 769px) {
  .ReactModal__Content {
    width: 500px; /* Width for larger screens */
    padding: 30px;
  }
}

/* General styling for the section */
.contact-section {
  background-color: #003366;
  padding: 50px 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Responsive container for smaller screens */
@media (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
  }
  .contact-section {
    padding: 30px 0;
  }
  .info-wrapper {
    margin-bottom: 30px;
  }
}

/* Info Section */
.info-wrapper h1 {
  color: white;
}
.info-wrapper ul {
  list-style: none;
  padding: 0;
}
.info-wrapper ul li {
  font-size: 1.1rem;
  color: white;
  margin-bottom: 10px;
}

/* Form Wrapper */
.form-wrapper {
  background-color: #e9ecef;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .form-wrapper {
    padding: 20px;
  }
}
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  display: block;
  font-weight: bold;
  color: #003366;
  margin-bottom: 5px;
}
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}
textarea {
  height: 100px;
  resize: vertical;
}
#contact-form {
  scroll-margin-top: 100px; /* Adjust for the height of your header */
}

/* WhatsApp Toggle Switch */
.toggle-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 50px;
  transition: 0.4s;
}

.slider::before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #003366;
}

input:checked + .slider::before {
  transform: translateX(25px);
}

@media (min-width: 375px) and (max-width: 424px) {
  .switch {
    width: 64px; /* Adjust width for mobile */
    height: 25px; /* Adjust height to maintain proportion */
  }
}

@media (min-width: 425px) {
  .switch {
    width: 55px; /* Adjust width for mobile */
    height: 25px; /* Adjust height to maintain proportion */
  }
}


/* CTA Button */
.cta-button {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}
.cta-button:hover {
  background-color: #002244;
}

/* Checkbox Wrapper */
.checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  font-size: 1rem;
}
.checkbox-wrapper input[type='checkbox'] {
  margin-right: 8px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.checkbox-wrapper label {
  color: #003366;
}
.checkbox-wrapper a {
  color: #4B0082;
  text-decoration: none;
  margin-left: 5px;
}
.checkbox-wrapper a:hover {
  text-decoration: underline;
}

/* Modal Close Button Styling */
.modal-close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.modal-close-button:hover {
  background-color: #002244;
}
.privacy-policy {
  cursor: pointer;
  border: none;
  font-weight: bold;
  font-size: 14px;
  color: #003366 !important;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.privacy-policy:hover {
  background-color: rgba(0, 51, 102, 0.1);
}

.privacy-policy:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 51, 102, 0.5);
}
