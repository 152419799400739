/* Terms and Conditions Page Styles */
.terms-container {
  font-family: 'Arial', sans-serif;
   /* Dark background for modern feel */
  color: rgb(110, 100, 131); /* Light text for readability on dark background */
  padding: 50px;
  line-height: 1.8;
  max-width: 900px;
  margin: 0 auto; /* Centers the container */
  border-radius: 10px;
  
}

.terms-title {
  color: #1D3557; /* Bright accent color for title */
  font-size: 3rem;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 2px;
}

.section-heading {
  color: #A9A9A9; /* Softer grey for section headings */
  font-size: 2rem;
  margin-top: 30px;
  margin-bottom: 15px;
  
}

.terms-paragraph {
  color: rgb(95, 88, 116);
  margin-bottom: 15px;
  line-height: 1.8;
  
}

.terms-list {
   /* Bullet points */
  margin: 0 auto;
  padding: 0;
  text-align: left; /* Align list items left */
  
}

.terms-list li {
  margin-bottom: 10px;
  font-weight: normal;
}

.highlight {
  background-color: #1D3557; /* Slightly lighter background */
  color: #2EC4B6;
  font-weight: bold;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

/* Add hover effects for better interactivity */


/* Responsive Design */
@media (max-width: 768px) {
  .terms-title {
      font-size: 2.5rem;
  }

  .section-heading {
      font-size: 1.6rem;
  }
}

@media (max-width: 480px) {
  .terms-title {
      font-size: 2rem;
  }

  .section-heading {
      font-size: 1.3rem;
  }

  .terms-container {
      padding: 20px;
  }
}
