/* styles.css (Global Styles) */
html, body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif; /* Apply the font globally */
  font-size: 16px;
  color: #333; /* Optional: Set a default text color */
  background-color: #fff; /* Optional: Set a default background color */
  line-height: 1.6; /* Optional: Set line-height for better readability */
}

/* Reset styling for headings */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 600; /* Consistent font weight for all headings */
}
