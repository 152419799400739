/* About Us Page Styles */
.about-container {
  font-family: 'Arial', sans-serif;
  background-color: white; /* White background */
  color: black; /* Black text for readability */
  padding: 20px;
  line-height: 1.6;
  max-width: 900px;
  margin: 0 auto; /* Centers the container */
  border-radius: 10px;
  
}

/* Title Styling */
.about-title {
  color: #003366; /* Title color */
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center; /* Centers the title */
  letter-spacing: 1.5px; /* Slight letter spacing for elegance */
}

/* Paragraph Styling - No Background */
.about-paragraph {
  color: black; /* Plain black text */
  padding: 0;
  margin-bottom: 15px;
  line-height: 1.6;
  text-align: justify; /* Aligns paragraphs for a neat look */
}

/* Section Headings */
.section-heading {
  color: #1D3557; /* Different color for section headings */
  font-size: 1.8rem;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center; /* Centers the section headings */
}

/* List Styling */
.about-list {
  list-style-type: disc;
  margin: 0 auto; /* Centers the list */
  padding: 0;
  width: fit-content;
}

.about-list li {
  margin-bottom: 10px;
  font-weight: normal;
  padding: 0;
  border: none;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-title {
      font-size: 2rem;
  }

  .section-heading {
      font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .about-title {
      font-size: 1.5rem;
  }

  .section-heading {
      font-size: 1.3rem;
  }

  .about-container {
      padding: 10px;
  }
}
